import { Role, RolePivot } from '@appbolaget/aware-model';

export enum ECrudPermission {
    Read = 1,
    Create = 2,
    Update = 4,
    Delete = 8,
}

export const AllPermissions = 15;

export type CrudPermissionKeys = keyof typeof ECrudPermission;
export type PermissionFormOutput<TRolePivot extends RolePivot = RolePivot> = Array<{
    role: Role<TRolePivot>;
    permissions: Array<{ permission: CrudPermissionKeys; value: boolean }>;
}>;
export type PermissionFormInput = { [roleId: string]: number };
